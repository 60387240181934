<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;margin-right:10px;" />
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

					</div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		ReportServiceProxy,LayoutOrgServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "Departmental-issues",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
			this.getData();
		},
		data() {
			return {
				zh_CN,
				isLoading: true, //加载中
				startTime: undefined,
				endTime: undefined,
				nowTime: [],
				
				chartData: [],
				data: undefined,
				deptList: [],
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.l('部门问题关闭率'),
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// data: [this.l('FindNum'), this.l('CloseNum'), this.l('CloseRate')],
						top: '97%',
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '',
						data: this.deptList,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
							},
						},
						{
							type: 'value',
							name: this.l('关闭率') + '(%)',
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}%',
							},
							splitLine:{
							    show:true,
							    lineStyle:{
							        type:'dashed'
							    }
							}
						},
					],
					series: this.chartData,
				}
			}
		},
		methods: {
			moment,
			function() {
				// this.yearDate = moment(new Date());
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startTime = moment(startTime);
				this.endTime = moment(endTime);
			},
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getInspDeptIssueCloseRate(
					this.startTime,
					this.endTime
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.data = res;
					this.chartsData();
				})
			},

			chartsData() {
				let issueNumber = {
					name: this.l('问题数量'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeNumber = {
					name: this.l('关闭数量'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('关闭率'),
					type: 'line',
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				let issueData = this.data.issueList;
				let closeData = this.data.closeList;
				let closeRateData = this.data.closeRateList;
				this.deptList = [];
				for(let i in this.data.deptList) {
					this.deptList.push(this.data.deptList[i].name);
				}
				issueNumber.data = issueData;
				closeNumber.data = closeData;
				closeRate.data = closeRateData;
				this.chartData = [];
				this.chartData.push(issueNumber);
				this.chartData.push(closeNumber);
				this.chartData.push(closeRate);
				this.charts();
			},

			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				document.getElementById("mainScrapCode").style.height = "720px";
				this.myeChart.setOption(this.option);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//选择日期
			DiscoverytimeChange(date, dateString) {
				this.startTime = date[0];
				this.endTime = date[1];
				this.getData();
			},
		}
	}
</script>

<style>
</style>
